.custom-large-btn {
  min-width: 200px;
  background-color: #2A9D8F;
  color: white;
  padding: 5px 20px;
  border: 3px solid #2A9D8F;
  border-radius: 5px;
}

.custom-btn {
  background-color: #2A9D8F;
  color: white;
  padding: 5px 20px;
  border: 3px solid #2A9D8F;
  border-radius: 5px;
}

.disable-btn {
  background-color: lightgray;
  color: black;
  padding: 5px 20px;
  border-radius: 5px;
}

// .custom-large-btn:hover {
//   background-color: white;
//   color: #2A9D8F;
//   border: 3px solid #2A9D8F;
// }

.blank-btn {
  width: 200px;
  background-color: white;
  color: #2A9D8F;
  padding: 5px 30px;
  border: 3px solid #2A9D8F;
  border-radius: 5px;
}

.loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.image-cropper {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.dashboard-item:hover {
  background-color: #2A9D8F;
  color: white;
  border-radius: 10px;
}

.dashboard-item-selected {
  background-color: #2A9D8F;
  color: white;
  border-radius: 10px;
}

.custom-headline {
  color: #2A9D8F;
}

.custom-para {
  font-size: 20px;
}

.vertical-gap {
  margin: 100px 0px;
}

.active-dashboard-item {
  background-color: black;
}
